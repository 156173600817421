import { Injectable, signal } from "@angular/core";
import { IOrg } from "./information";

interface State {
  entities: IOrg;
}

const initialState: State = {
  entities: {
    name: "Roam",
    logoUrl: "./assets/svg/sidenav/roam-mobile-logo.svg",
    logoName: "Roam",
    fontSize: "22",
    bgColor: "black",
    isChangeLogo: false,
  },
};

@Injectable({ providedIn: "root" })
export class InformationStore {
  readonly #state = signal<State>(initialState);
  readonly state = this.#state.asReadonly();

  resetState(): void {
    this.#state.set(initialState);
  }

  readonly changeOrg = (data: IOrg) => {
    return this.#state.update(state => {
      return {
        ...state,
        entities: data,
      };
    });
  };

  readonly changeLogoUrlandName = (logoUrl: string, logoName: string) => {
    return this.#state.update(state => {
      return {
        ...state,
        entities: {
          ...state.entities,
          logoUrl,
          logoName,
        },
      };
    });
  };

  readonly changeFontSize = (fontSize: string) => {
    return this.#state.update(state => {
      return {
        ...state,
        entities: {
          ...state.entities,
          fontSize,
        },
      };
    });
  };

  readonly changeName = (name: string) => {
    return this.#state.update(state => {
      return {
        ...state,
        entities: {
          ...state.entities,
          name,
        },
      };
    });
  };

  readonly changeBgColor = (bgColor: string) => {
    return this.#state.update(state => {
      return {
        ...state,
        entities: {
          ...state.entities,
          bgColor,
        },
      };
    });
  };

  readonly changeStatusLogo = (status: boolean) => {
    return this.#state.update(state => {
      return {
        ...state,
        entities: {
          ...state.entities,
          isChangeLogo: status,
        },
      };
    });
  };
}
