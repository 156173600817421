import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  public userProfile: Subject<string> = new Subject<string>();
  constructor() { }
}
